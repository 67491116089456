<template>
  <section class="p-4">
    <div class="pt-12 max-w-[100vw] overflow-x-auto">
      <div v-if="data">
        <h1 class="text-4xl font-bold mb-4"> Tiders: {{ data.active.length }} </h1>
        <table class="w-full overflow-x-auto text-xs pl-4" >
          <tr> 
            <th>Fishing</th>
            <th>Boating</th>
            <th>Surfing</th>
            <th>Diving</th>
          </tr>
          <tr>
            <td>{{ data.fishing.length }}</td>
            <td>{{ data.boating.length }}</td>
            <td>{{ data.surfing.length }}</td>
            <td>{{ data.diving.length }}</td>
          </tr>
          </table>  
        <table class="w-full overflow-x-auto text-xs pl-4 mt-4" >
          <tr>
            <th>Created</th>
            <th>Name</th>
            <th>Address</th>
            <th>Last Sent</th>
            <th>Timezone</th>
            <th>Verified At</th>
            <th>Sent</th>
            <th>Vers</th>
            <th>Source</th>
            <th>Fishing</th>
            <th>Boating</th>
            <th>Surfing</th>
            <th>Diving</th>
            <th>Walking</th>
            <th>Other</th>
          </tr>
          <tr v-for="tider in data.active" :key="tider.id" :class="tider.unsubscribed_at ? 'bg-red-100' : ''">
            <td><span v-if="tider.unsubscribed_at">🔴 </span>{{ dayjs(tider.created_at).format('MM/DD/YYYY HH:mm') }}</td>
            <td>{{ tider.first_name }} {{ tider.last_name }}</td>
            <td>{{ tider.loc_address }}</td>
            <td>{{ tider.last_sent_at ?  dayjs(tider.last_sent_at).isToday() ? "✅ Today " + dayjs(tider.last_sent_at).format('h:mm A') : dayjs(tider.last_sent_at).format('MM/DD/YYYY h:mm A') : '🔴' }}</td>
            <td>{{ tider.timezone }}</td>
            <td>{{ tider.verified_at ? dayjs(tider.verified_at).format('MM/DD/YYYY HH:mm') : '🔴' }}</td>
            <td>{{ tider.send_count }}</td>
            <td>{{ tider.verification_attempts }}</td>
            <td>{{ tider.source }}</td>
            <td>{{ tider.fishing ? '✔️' : '' }}</td>
            <td>{{ tider.boating ? '✔️' : '' }}</td>
            <td>{{ tider.surfing ? '✔️' : '' }}</td>
            <td>{{ tider.diving ? '✔️' : '' }}</td>
            <td>{{ tider.walking ? '✔️' : '' }}</td>
            <td>{{ tider.other ? '✔️' : '' }}</td>
          </tr>
        </table>
      </div>
      <div v-if="data && data.inactive" class="mt-12">
        <h1 class="text-4xl font-bold mb-4"> Inactive: {{ data.inactive.length }} </h1>
        <table class="w-full overflow-x-auto text-xs pl-4 mt-4" >
          <tr>
            <th>Created</th>
            <th>Name</th>
            <th>Address</th>
            <th>Last Sent</th>
            <th>Timezone</th>
            <th>Verified At</th>
            <th>Sent</th>
            <th>Vers</th>
            <th>Source</th>
            <th>Fishing</th>
            <th>Boating</th>
            <th>Surfing</th>
            <th>Diving</th>
            <th>Walking</th>
            <th>Other</th>
          </tr>
          <tr v-for="tider in data.inactive" :key="tider.id" :class="tider.unsubscribed_at ? 'bg-red-100' : ''">
            <td><span v-if="tider.unsubscribed_at">🔴 </span>{{ dayjs(tider.created_at).format('MM/DD/YYYY HH:mm') }}</td>
            <td>{{ tider.first_name }} {{ tider.last_name }}</td>
            <td>{{ tider.loc_address }}</td>
            <td>{{ tider.last_sent_at ?  dayjs(tider.last_sent_at).isToday() ? "✅ Today " + dayjs(tider.last_sent_at).format('h:mm A') : dayjs(tider.last_sent_at).format('MM/DD/YYYY h:mm A') : '🔴' }}</td>
            <td>{{ tider.timezone }}</td>
            <td>{{ tider.verified_at ? dayjs(tider.verified_at).format('MM/DD/YYYY HH:mm') : '🔴' }}</td>
            <td>{{ tider.send_count }}</td>
            <td>{{ tider.verification_attempts }}</td>
            <td>{{ tider.source }}</td>
            <td>{{ tider.fishing ? '✔️' : '' }}</td>
            <td>{{ tider.boating ? '✔️' : '' }}</td>
            <td>{{ tider.surfing ? '✔️' : '' }}</td>
            <td>{{ tider.diving ? '✔️' : '' }}</td>
            <td>{{ tider.walking ? '✔️' : '' }}</td>
            <td>{{ tider.other ? '✔️' : '' }}</td>
          </tr>
        </table>
      </div>
      <div v-if="!data" class="w-full max-w-md">
        <br>
        <input type="password" v-model="str" class="w-full h-12 border-2 border-gray-300 rounded-md p-2" autocomplete="off" autofocus="true" @keyup.enter="submit">
        <button v-if="str.length > 0" @click="submit" class="bg-blue-500 text-white px-4 py-2 rounded-md mt-4">Submit</button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const store = TiderStore();
const str = ref('');
const data = ref(null)
import dayjs from 'dayjs';

onMounted(() => {
  if (localStorage.getItem('xdk')) {
    str.value = localStorage.getItem('xdk')
  }
})

const submit = () => {
  store.doSubmit(str.value).then((response) => {
    localStorage.setItem('xdk', str.value)
    console.log("Submitted", response.data)
    data.value = response.data
  })
  
}

</script>

<style scoped>  
table {
  width: 100%;
}
td {
  @apply border-2 border-gray-300 p-2 whitespace-nowrap;
}
</style>
