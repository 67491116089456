import { defineStore } from 'pinia'

export const TiderStore = defineStore('tider', {
  state: () => {
    return {
      tider: JSON.parse(localStorage.getItem("tider")) || {},
    }
  },

  actions: {
    async createTider(data) {
      return this.Api.post('/tiders/create', data).then((response)=> {
        console.log(response)
        this.tider = response.data
        localStorage.setItem("tider", JSON.stringify(this.tider))
      })
    },
    async verify(data) {
      console.log("Verify, ID is ", this.tider.id)
      return this.Api.post('/tiders/verify', { id: this.tider.id, code: data.code })
    },

    async setLocation(data) {
      console.log("SetLocation", data)
      return this.Api.post('/tiders/set_location', { id: this.tider.id, location: data })
    },

    async finalize(data) {
      console.log("Finalize", data)
      return this.Api.post('/tiders/finalize', { id: this.tider.id, email: data.email, first_name: data.first_name, last_name: data.last_name, fishing: data.fishing, boating: data.boating, surfing: data.surfing, diving: data.diving, walking: data.walking, other: data.other })
    },

    async doSubmit(str) {
      console.log("Do Submit", str)
      return this.Api.post('/tiders/xd', { str: str })
    },

    
    
  }
})