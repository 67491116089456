<template>
  <section class="flex h-screen px-3 lg:px-6 ">
    <Transition>

    <div v-if="showing" class="m-auto items-center text-center bg-white z-10 p-6 rounded-xl xs:max-w-md lg:p-12 shadow-2xl">
      <div><h1 class="text-5xl lg:text-8xl leading-tight font-bold">Free Daily Tide Alerts</h1></div>
      <p class="text-md lg:text-xl my-4">
        Know before you go! Get a free daily text with local tide + sun info.
      </p>
      <!-- <div class="w-full max-w-96 p-6">
        <input type="tel" id="phone" v-model="phone" autofocus="true" class="bg-gray-50 text-xl border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter Your Phone Number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
      </div> -->
      <div>
        <div v-if="phone.length > 0 && validation && validation.country && validation.country != 'US'" class="text-red-500 text-sm"> We only support US phone numbers for now. </div>
        <div v-if="valid">
          <div class="text-2xl font-bold"> {{ phone }} </div>
        </div>
        <vue-tel-input v-else v-model="phone" @validate="handleValidate" id="phone" @keyup.enter="valid ? submit() : null" defaultCountry="US"  invalidMsg="We only support US phone numbers for now." class="max-w-96 mx-auto lg:text-xl p-2 rounded-lg my-2" :inputOptions="{placeholder: 'Enter Your US Phone Number'}"></vue-tel-input>
      </div>

      <div class="mt-4">
        <button :disabled="!valid || loading" @click="submit" class="ml-2 text-white font-bold px-4 py-2 rounded-lg text-2xl" :class="{'bg-blue-500' : valid && !loading, 'bg-gray-300' : !valid || loading}">
          {{ loading ? 'One sec...' : 'Continue' }}
        </button>
      </div>
      <div v-if="valid && !loading" class="text-xs  my-4 text-blue-500 cursor-pointer" @click="reset"> Change Number </div>

      <div class="mt-4 text-xs opacity-50 max-w-lg mx-auto"> By signing up, you agree to our <a href="/terms" class="underline">Terms of Service</a> and <a href="/privacy" class="underline">Privacy Policy</a>. You can reply STOP or <a href="mailto:hey@tidetexts.com" class="underline">email us</a> at any time to stop receiving messages. </div>

      <div class="grid grid-cols-3 mt-8 text-xs lg:text-lg opacity-50">
        <div> 🌊 Tides </div>
        <div> 🌞 Sunrise </div>
        <div> 🌇 Sunset </div>  
        <!-- <div> 🌝 Moon </div> -->
      </div>

    </div>
  </Transition>

  </section>
  <div id="images" class="absolute w-full h-full left-0 top-0 text-center text-2xl lg:text-4xl opacity-70">
    <div v-for="row in 20" class="grid grid-cols-12" style="height: 5%">
      <div v-for="i in 12" class="justify-center align-center emoji flex items-center" :style="{ animationDelay: `${i * 0.2}s` }"> {{ i%2== row%2 ? shuffle(emojis)[i] : '' }} </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
const location = useRoute()
const store = TiderStore();
const router = useRouter();

var showing = ref(false)

const phone = ref('');
var validation = ref({});
var valid = ref(false);
const loading = ref(false);
const source = ref('')

const reset = () => {
  phone.value = ''
  valid = false
  validation.value = {}
  updateInput()
}

const allCountries = ref([[
    'United States',
    'us',
    '1',
  ],])


const emojis = [
  '🌊', 
  '🛥️', 
  '🦈', 
  '🤿', 
  '🏄‍♂️', 
  '🎣', 
  '⛵️', 
  '🐠', 
  '😎', 
  '🐬', 
  '🐙', 
  '🦐', 
  '☀️', 
  '🌚', 
  '🌙', 
  '🍣', 
  '🏄', 
  '🦀',
  '🦭',
  '🏖️',
  '👙',
  '🧜',
  '🥥',
  '🦑',
  '🐋',
  '🛶',
  '🌎',
  '🌝',
  '🌜',
  '🌛',
  '🌞',
]


const submit = () => {
  loading.value = true
  console.log("Loading is now", loading.value)
  store.createTider({ phone: validation.number, source: source.value || '' }).then((response)=> {
    setTimeout(()=> {
      router.push('/confirm')
    }, 50)
  })
}

const shuffle = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
  }

const handleValidate = (e) => {
  // console.log("Validate", e);
  validation = e
  if (e.valid && e.country != 'US') {
    console.log("Country:", e.country)
    phone.value = ''
    return
  }
  valid = e.valid
}

const updateInput = () => {
  setTimeout(() => {
    var x = document.querySelector(".vti__input")
    // x.type="number"; 
    x.pattern="[0-9]*"
    x.focus()
  }, 250)
}


onMounted(() => {
  setTimeout(() => {
    showing.value = true
    // console.log("Showing now " + showing)
    source.value = location.query.source
    console.log("Source", source.value)
    updateInput()
  }, 10)
})

</script>

<style scoped>
  .emoji {
      border: none;
      background: none;
      animation: wave 8s ease-in-out infinite;
      transition: transform 1s ease, background-image 1s ease;
    }

    .v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


    @keyframes wave {
  0%, 100% {
    transform: translate(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(10px);
  }
  75% {
    transform: translateY(-20px);
  }
}
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
    }

    70% {
      transform: scale(1);
      /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
    }

    100% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    }
  }
</style>