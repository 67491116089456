import { createWebHistory, createRouter } from 'vue-router'

import Dashboard from './views/pages/Dashboard.vue';
import Confirm from './views/pages/Confirm.vue';
import Setup from './views/pages/Setup.vue';
import Finish from './views/pages/Finish.vue';
import Success from './views/pages/Success.vue';
import Terms from './views/pages/Terms.vue';
import Privacy from './views/pages/Privacy.vue';
import Xd from '@/front/views/pages/xd.vue';


const router = createRouter({
  history: createWebHistory(`/${I18n.prefix}`),
  routes: [
    // Authentication
    { path: '/', component: Dashboard, name: 'root_path' },
    { path: '/confirm', component: Confirm, name: 'confirm_path' },
    { path: '/setup', component: Setup, name: 'setup_path' },
    { path: '/finish', component: Finish, name: 'finish_path' },
    { path: '/success', component: Success, name: 'success_path' },
    { path: '/terms', component: Terms, name: 'terms_path' },
    { path: '/privacy', component: Privacy, name: 'privacy_path' },
    { path: '/xd', component: Xd, name: 'xd_path' },
  ]
});

// Handles 404 Not found
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    // window.location.href = '/404'
    console.log("No route")
  } else {
    next();
  }
});

export default router;